import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { API_URL } from 'constants/api';

export const pageInfoApi = createApi({
  reducerPath: 'pageInfo',
  tagTypes: ['PageInfo'],
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getPageInfoByName: builder.query<any, { routing: string; lang: string }>({
      query: ({routing, lang}) => ({
        url: 'PageGet',
        params: {
          routing,
          lang,
        }
      }),
      transformResponse: (result: any) => result?.data,
      providesTags: ['PageInfo']
    })
  })
});


export const {
  useGetPageInfoByNameQuery,
  endpoints,
  reducer,
  middleware
} = pageInfoApi;
